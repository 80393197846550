import "@/styles/globals.css";

import { buildDefaultTheme } from "@/styles/theme";
import type { AppProps } from "next/app";
import { ThemeProvider } from "styled-components";
import NextNProgress from "nextjs-progressbar";
import { hslToHex } from "@/lib/colors";
import { Poppins } from "next/font/google";
import setupLogRocketReact from "logrocket-react";
import LogRocket from "logrocket";
import { useEffect, useRef } from "react";
import { NotificationsProvider } from "@/hooks/NotificationsProvider";
import { AuthProvider } from "@/providers/auth.provider";
import { Toaster } from "sonner";

const poppins = Poppins({
  subsets: ["latin"],
  weight: ["100", "200", "300", "400", "500", "600", "700"],
});

export default function App({ Component, pageProps }: AppProps) {
  const theme = buildDefaultTheme();

  const logRocketRef = useRef<boolean>(false);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      !logRocketRef.current &&
      process.env.NEXT_PUBLIC_STAGE === "prod"
    ) {
      LogRocket.init("u0bgqp/gogive-charity-portal", {
        dom: {
          inputSanitizer: true,
        },
      });
      setupLogRocketReact(LogRocket);
      logRocketRef.current = true;
    }
  }, []);

  return (
    <AuthProvider>
      <NotificationsProvider>
        <ThemeProvider theme={theme}>
          <NextNProgress
            color={hslToHex(theme.colors.secondary.accent)}
            startPosition={0.3}
            stopDelayMs={200}
            height={3}
            showOnShallow
            options={{ easing: "ease", speed: 500 }}
          />
          <style jsx global>{`
            html {
              font-family: ${poppins.style.fontFamily};
            }
            body {
              margin: 0px;
            }
            * {
              box-sizing: border-box;
              font-family: ${poppins.style.fontFamily};
            }
          `}</style>
          <Toaster position="bottom-right" />
          <Component {...pageProps} />
        </ThemeProvider>
      </NotificationsProvider>
    </AuthProvider>
  );
}
